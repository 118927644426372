import React from "react"
import slideshowStyles from "./slideshow.module.css"

class Slideshow extends React.Component {
  constructor(props) {
      super(props);

      this.state = {currentSlide: 0, images: props.images}
  }

  onNextSlide = () => {
    this.setState(this.nextSlide);
  }

  nextSlide(state) {       
    return { ...state, currentSlide: state.currentSlide + 1 };
  }

  onPreviousSlide = () => {
    this.setState(this.previousSlide);
  }

  previousSlide(state) {
    return { ...state, currentSlide: state.currentSlide - 1 };
  }

  render() {
    if(this.state.images[this.state.currentSlide] === null) {
        return (<></>)
    }

    let total = this.state.images.length;
    let previousStyle = {
        display: this.state.currentSlide === 0 ? 'none' : 'block'
    }

    let nextStyle = {
        display: this.state.currentSlide < (total-1) ? 'block' : 'none'
    }

    let currentSlideStyle = {
        display: total === 1 ? 'none' : 'block'
    }

    const currentImage = this.state.images[this.state.currentSlide].childImageSharp.fixed;
    return (
      <div>
          <div class={slideshowStyles.container}>
            <div class={slideshowStyles.numbertext} style={currentSlideStyle}>{this.state.currentSlide+1} / {total}</div>
            <img alt="" src={currentImage.src} style={{width: '100%'}}/>

            <button class={slideshowStyles.prev} style={previousStyle} onClick={this.onPreviousSlide}>&#10094;</button>
            <button class={slideshowStyles.next} style={nextStyle} onClick={this.onNextSlide}>&#10095;</button>
          </div>
      </div>
    )
  }
}

export default Slideshow
